/************************
* Factors Footer start
************************/

.factors-page {
    .alert-mobile {
        margin-bottom: 20%;
        margin-left: -10px;
        margin-right: -10px;
    }
    .select-factors-text {
        padding-left: 12px;
        @include media-breakpoint-down(sm) {
            padding-left: 5px;
        }
    }
    a {
        text-decoration: none;
        color: $white;
    }
    @include media-breakpoint-down(sm) {
        .mobile-factor-hs {
            display: none;
        }
    }
    .info-text {
        color: nth($blue-shades, 17)!important;
    }
    @at-root {
        .disabledp2 {
            pointer-events: none;
            opacity: 0.6; 
            outline: none!important;
        }
    }
    .factors-box-bg-color {
        &.blue {
            // background-color: nth($blue-shades, 6);
            background-color: nth($green-shades, 8);
        }
        &.green {
            background-color: nth($green-shades, 3);
        }
    }
    .factors-list {
        @include media-breakpoint-down(sm) {
            margin-bottom: 60px;
            padding: 0;
        }
        .factor-color{
            color: #424242;
        }
    }
    .list-styles {
        margin: 0 0 7px 5px;
        padding: 8px 5px;
        background-color: nth($grey-shades, 1);
        border: 1px solid rgba(0, 0, 0, 0.125);
        cursor: pointer;
        span {
            color: nth($black-shades, 1);
        }

        .word-3-dots {
            max-width: 90%;
            vertical-align: top;
            @extend .three-dots;
            @include custom-device(768px, 992px) {
                max-width: 86%!important;
                left: 0;
            }
        }
    }
    @include large-device(768px) {
        .itemsp2.slideLeft {
            margin-left: -550px;
            @include custom-device(768px, 992px) {
                margin-left: -394px!important;
            }
        }
        .slider-width {
            width: 3500px;
            overflow-x: hidden;
            overflow-y: hidden;
        }
        #list-slider {
            overflow: hidden;
            width: 1120px;
            position: relative;
            @include custom-device(768px, 992px) {
                width: 680px;
            }
        }
        .spaceOSDiv {
            -webkit-flex: 0 0 2em;
            -ms-flex: 0 0 1.7em!important;
            flex: 0 0 3em!important;
            position: relative;
        }
        .itemsp2 {
            width: 1120px;
            float: left;
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            display: inline-block;
            @include custom-device(768px, 992px) {
                width: 768px;
            }
        }
    }
    /******* empty div ********/
    .important-factors {
        min-width: 375px;
        color: $white;
    }
    .factorclass{
        /* This code for remove the button default styles */
        background: none;
        box-shadow: none;
        border: none;
        color: $white;   
        padding: 0;
    }

    .icon-box {
        @extend .factors-box-bg-color;
        padding: 11px 10px 17px 13px;
        margin-right: 4px;
        @include media-breakpoint-down(sm) {
            padding: 11px 10px 15px 13px
        }
    }
    .list-box {
        @extend .factors-box-bg-color;
        padding: 10px 5px;
        width: 72%;
        display: inline-block;
    }
}


/************************
* Factors Footer Start
************************/

.factors-footer {
    @include common-border-radius(10px, 0);
    @include common-border(1px, 1px, 1px, 1px, $white);
    width: 40%;
}

.view {
    background-color: nth($grey-shades, 12);
    color: $white;
    @include height-width(100%, 65px);
    .next-button {
        @extend .factors-footer;
        margin: 17px 10px 0 0;
        float: right;
    }
}


/************************
* Factors Footer End
************************/


/****************************
* EQ Result start
****************************/

.eq-result-page {
    .wil-select-right::after {
        border-top: 500px solid nth($green-shades, 6)!important;
    }
    .well-p2 {
        // display: table;
        // vertical-align: middle;
        @include common-border-radius(4px, 0);
        border: 1px solid nth($grey-shades, 8); //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
        margin-bottom: 5px;
        height: 105px;
        padding: 10px 5px 0 5px;
        transition: all .2s ease-in-out;
        width: $width-value;
        border-radius: 5px;
    }
    .green-box {
        height: auto;
        padding-bottom: 20px;
        @include media-breakpoint-down(sm) {
            height: auto;
        }
        .header-text {
            color: nth($green-shades, 6);
            margin: 23px 6px 6px 0px;
            font-size: $font-sizes-14 !important;
            line-height: 1.1;
        }
        .result-text {
            @include common-border(0px, 0px, 0px, 1px, nth($grey-shades, 15));
            margin-top: 50px;
            @include media-breakpoint-down(sm) {
                border-left: none;
                margin-top: 10px;
                .result-para {
                    display: block!important;
                }
            }
            .result-para {
                @include media-breakpoint-only(lg) {  
                    display: block!important;
            }
                vertical-align: middle !important;
                padding: 10px 35px;
                div:first-child {
                     
                    width: 75px;
                    
            }
            }
        }
     
        .result-score-color {
            color: nth($green-shades, 5)!important;
        }
    }
    .eq-last-child-card {
        div:last-child .text-cards {
            margin: 0;
        }
    }
}


/****************************
* End of EQ Result
****************************/


/****************************
* Start of CCI Result
****************************/

.cci-result-page {
    .top-three-careers {
        padding-bottom: 15px;
        .inner-card {
            @include common-border(1px, 1px, 1px, 1px, nth($grey-shades, 8));
            padding: 10px 10px 6px 10px;
            @media(max-width:767px){
                padding: 10px 10px 0px 10px;
            }
        }
        .top-cards{
            @media(max-width:767px){
                margin-bottom:-12px //to alignin in mobile screens
            }
        } 
        .card-block {
            padding: 0;
            .header_1 {
                @include common-header($white, $font-sizes-18 !important, none, bold);
                margin: 30px;
            }
            .header_1_print{
                height: 150px;
                font-size: 50px;
            }
        }
    }
    @include media-breakpoint-up(md) {
        .height-cci-cards {
            height: 36em!important;
        }
        .spacing-divs {
            flex: 0 0 4em!important;
        }
    }

    .small-cards {
        padding: 9px 0;
        h6 {
            font-size: $font-size-base;
        }
        i,
        .card-media-body span {
            font-size: $font-sizes-13;
        }
        a {
            color: nth($green-shades, 20);
            text-decoration: underline;
            font-size: $font-sizes-3;
        }
    }
    .card-media-body {
        color: $white;
        text-align: center;
        height: 63px;
        .score {
            width: 63px;
            span {
                font-size: $font-sizes-9;
            }
            .score-text{
                font-size: 24px;
            }
        }
    }
    .card-media-body,
    .card-media-left {
        border-radius: 0;
    }
}


/***************************
* End of CCI Result
****************************/


/****************************
* Start of LSS Result
****************************/

.lss-result-page {
    .card-border {
        @include common-border(1px, 1px, 1px, 1px, nth($grey-shades, 8));
        #res-lss-plp4 {
            background-color: nth($white-shades, 10);
        }
        img {
            margin: 30px 0;
        }
        .lss-horizontal-graph {
            margin: 10px 0;
            align-items: center;
            .result-text {
                margin-top: 4px;
                @include media-breakpoint-down(sm) {
                    margin-top: 6px;
                }
            }
            .result-bar {
                @include height-width(100%, auto);
            }
        }
    }
    .lss-card-height{
        height: 380px;
        overflow-x: hidden;
        overflow-y: scroll;
        // padding: 10px 10px 0 40px;
        }
    .card-top-height ,
    .card-footer-plp4,
     .carousel-item {
        b {
            color: nth($grey-shades, 11);
        }
    }
    .score-circle {
        padding: 0px 0 11px 0;
        background-color: nth($blue-shades, 18);
        @include common-border-radius(50%, 1px);
        @include height-width(55px, 55px);
        @include common-border(1px, 1px, 1px, 1px, #2c5165);
        @include common-header($white, $font-sizes-16, center, bold);
        @include common-border-shadow(0px, 0px, 5px, 1px, #2c5165, 1px);
        @include media-breakpoint-down(sm) {
            @include height-width(40px, 40px);
            padding: 5px 0 11px 0;
            font-size: $font-size-lg;
        }
    }
    .study-tips-block {
        background-color: nth($grey-shades, 8);
        // border-top: 1px solid rgba(0, 0, 0, 0.125);

        .header_6 {
            font-size: 1rem!important;
               } 

        .text-arrow {
            display: block;
            padding: 0;
            & span,
            & .right-arrow {
                display: table-cell;
                padding: 9px 15px;
            }
            .right-arrow {
                background: nth($green-shades, 32);
                @include common-header($white, $font-sizes-11, none, none);
            }
        }
    }
}


/****************************
* End of LSS Result
****************************/


/****************************
* Start of WES Result
****************************/

.wes-result-page {
    .text-icon {
        list-style: none;
        color: $white;
        display: inline;
        vertical-align: middle;
        li {
            // padding: 5px 0 0 5px;
        }
        img {
            width: 36px;
        }
    }
    .score-card {
        padding: 10px;
        width: 150px;
        height: 150px;
        color: $white;
        vertical-align: middle;
        border-radius: 5px 0 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .score {
            display: block;
            font-size: $font-sizes-13;
        }
        span:first-child {
            font-size: $font-sizes-7 !important;
        }
    }
    .your-score {
        font-size: $font-sizes-3;
        // text-transform: capitalize;
        b {
            color: nth($grey-shades, 13);
        }
        width: 100%;
        margin: auto;
    }
    // For lower case card text-WES Results
    .your-score-skill-tile-text {
        text-transform: initial;
    }
}


/****************************
* End of WES Result
****************************/


/****************************
* Start of IP and Wil Result
****************************/

.chart-result {

    
    .fa-star{
        -webkit-text-stroke-color: orangered;
        -webkit-text-stroke-width: 1px;
         color: yellow;
         font-size: 1.25rem;
    }
    @at-root {
        .panel-body {
            background-color: $white;
            height: auto;
            @include common-border(1px, 1px, 1px, 1px, nth($grey-shades, 8));
            @include media-breakpoint-down(sm) {
                padding: 10px 0 10px 0;
            }
        }
    }
    .columns-ip-result {
        list-style-type: none;
        columns: 2;
    }
    .three-coloumns-ip-result {
        list-style-type: none;
        columns: 3;
        // float: left;
        @include media-breakpoint-up(md) {
            list-style-type: none;
            columns: 2;
        }
    }
    @at-root{
    .square-box {
        border-radius: 9px;
        @include height-width(14px, 14px);
        display: inline-block;
        background-color: nth($red-shades, 3);
        @include common-border(0px, 0px, 0px, 0px, $black);
        @media (max-width: 767px) {
            @include height-width(10px, 10px);
        }
      .pq-img-card & {
        
        @media (max-width: 767px) {
        
        @include position (relative, $top: 0px!important);
        
        }
        
        
        }
    
        
    }
    .toptwo-result-box{
        border: 2px solid nth($sky-blue, 7);
        border-radius: 5px;
        padding:18px;
        width: 30%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-sizes-21;
        margin: 0 40px;
        img {
            width: 35px;
            height: 35px;
        }
        @include custom-device(768px, 992px) {
            width: 35%;
            margin: 0 20px;
        }
                                @include media-breakpoint-down(md) {
            width: 100%;
            margin: 0 0 10px 0;
        }
    }
}
    .result-text {
        width: 100px;
        display: inline-flex;
        z-index: 99;
        &.list-text {
            width: 130px;
         }
        @include large-device(992px) {
            width: 110px;
        }
        &.list-wil {
            width: 130px;
            @include media-breakpoint-up(md){
                width: 170px;
            }
            @media (max-width: 767px) {
                display:inline-block;
                overflow:hidden;
                text-overflow: ellipsis;
                text-decoration: none!important;
            }
        }
        &.list-occ-wil {        
            line-height: 16px;
            display: inline-block;
            text-overflow: ellipsis;
            text-decoration: none!important;
        }
    }
    .result-text-print{
        width:115px;
    }
    .result-score {
        @include common-header(nth($green-shades, 29), 1.25em, right, bold);
        width: 20px;
   }
    .result-list {
        &.height {
            @media (min-width: 767px) {
                min-height: 135px;
            }
        }
        ul {
            list-style: none;
            padding: 0px; 
            column-count: 2;
            @media (max-width: 480px) {
                column-count: 1;
            }
            @media (min-width: 992px) {
                column-count: 3;
            }
            li {
                padding: 5px 0;
                display: inline-flex;
                align-items: center;
                @media (max-width: 480px) {
                    display: flex;
                }
            }
            &.ae-occ-result-list {
                column-count: 2;
                @media (max-width: 767px) {
                    column-count: 1;
                    li{
                        display: block;
                    }
                }
                .list-wil {
                    @include large-device(992px) {
                        width: 145px;
                    }
                }
            }
        }
    }
    
    .highlight-text {
        @include common-header(nth($green-shades, 29), $font-sizes-3, inherit, inherit);
        @include media-breakpoint-down(sm) {
            font-size: $font-sizes-3;
        }
        @include media-breakpoint-only(md) {
            font-size: 0.75em;
        }
    }
    &.reality-check-result {
        .result-list .columns-ip-result {
            column-count: 2;
            @media(max-width:480px){
                column-count: 1;
            }
        }
    }
}
/**************************** 
* End of IP and Wil Result
****************************/


/*****************************
 Start Ideas
 *****************************/

.chart-ideas-result {
    box-shadow: none!important;
    margin-right: auto;
    @extend .panel-body;
    .result-idea-card {
        @include common-border(1px, 1px, 1px, 1px, nth($red-shades, 2));
        @include media-breakpoint-up (lg) {
            width: 340px;
               } .square-box {
               @media (max-width: 767px) {
                @include position (relative, $top: 6px);
            }   
        }
        img {
            width: 26px;
        }
    }
    .list {
        list-style: none;
        li span {
            text-decoration: underline;
        }
    }
    @at-root{
    $colors-list: nth($red-shades, 5) nth($green-shades, 31) nth($green-shades, 20);
    @each $current-color in $colors-list {
        $i: index($colors-list, $current-color);
        .graphcolor-#{$i} {
            color: $current-color; 
            font-size: $font-size-base;
        }
        .graphbgcolor-#{$i} {
            background: $current-color; 
            font-size: $font-size-base;
        }
    }
}
    .card-title {
        @include common-header(nth($grey-shades, 22), 18px, none, none)
    }
    .result-list-box {
       ul li a {
        font-size: 0.875em;
        padding-top: 3px;
        position: absolute;
        @include media-breakpoint-down (sm) {
            padding-top: 8px;
        }
    }
        
    }
}
.nowrap-audio {
    text-wrap: nowrap;
    @include media-breakpoint-down (md){
        text-wrap: unset;
    }
}

@include custom-device(768px, 992px) {
    .ideas-result-cards .media-left-ip-result h6 {
        font-size: $font-size-xs;
    }
}


/***********************
End Ideas Result
************************/



/***********************
Begin Ability Result
************************/



.result-ability {  

    .three-coloumns-ip-result {        
        list-style-type: none;
        columns: 3;
        float: left;
                
        @include media-breakpoint-down(lg) {
            list-style-type: none;
            columns: 2;
        }
    }
    .text-width {
        @include media-breakpoint-only (md) {
        width: 125px!important;
        }
    }


.piechart-align {
        @include media-breakpoint-only (md) {
    @include position (relative, $top: -38px!important);
    }
@include custom-device(910px, 991px) {
        @include position (relative, $right: 30px!important);
}
}

.piechart-align-print svg{
    text{
      font-size: 14px;
    }
}

//.square-box {
 //   @media (max-width: 767px) {
 //       @include position (relative, $top: -4px!important);
  //  }
  //      }
  

.result-ability-card {
    border: 1px solid nth($orange-shades, 7);
   
    min-height: 190px;    
    @include media-breakpoint-up (lg) {
 width: 340px!important;
    }   

.card-block .inner-width {    
    width: 230px; 
    padding-top: 15px;
       @include media-breakpoint-down (sm) {
           width: 202px; 
       }
   
}
.input-group {
    font-size: $font-sizes-5;
}

.text-bottom {
     display: table-cell;
    text-align: center;
    width: 100%;
    padding-top: 30px;
    @include media-breakpoint-down(md) {
      padding-top: 24px;  
    }
    
}
}
.ability-button {
    background: nth($orange-shades, 7);
    border: none;
    border-radius: 8px;
    width:100%;
    color: white;
    padding: 8px;
     border: 2px solid white!important;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1) 0s, background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: box-shadow, transform;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .ae-popup-result-p{
        span, p{
            text-align: left;
        }
    }
    .ability-bg {
         background-color: nth($white-shades, 6);
         border-radius:  8px;
          h3 {
                color: nth($blue-shades, 11 );
             }
        .ability-button-tree {
               @extend .ability-button;               
               padding: 18px 38px!important;
               @include media-breakpoint-only(md) {
                   height: 80px;
                   padding: 13px 38px!important;
               }
           }
        
           }

    .ability-high { 
        color: nth($orange-shades, 7);
        }
        .ability-medium {
            color: nth($green-shades, 35);
        }
        .ability-low {
            color: nth($blue-shades, 22);
         }



}

 
.ideas-result-cards{

    column-count: 3;
    display: grid; 
    @media (max-width: 767px){
        column-count: 1;
        display: block; 
    }
}

.wes-result-cards{
    column-count: 3;
    display: block;
    @media (max-width: 767px){
        column-count: 1;
    }
    @media only screen and (min-width:768px) and (max-width:991px){
        column-count: 2;
    }
}

 

.circle-number {
    float: right;
        position: relative;
        left: 35px;
        top: 5px;
        background: $blue;
        color: wheat;
        padding: 6px;
        border-radius: 50%;
}
.ability-model {
    .graph-block  .ability-text-modal { 
        width: 155px;
        @include media-breakpoint-down(xs) {
            width: 110px;
            li{
                word-break: break-all;
            }
        }
        @media (max-width: 414px){
            width: 70px;
        }  
    }
}

.reality-check-result  {
    .result-text {
        @include media-breakpoint-only(md) { 
            font-size: $font-sizes-2;
            width: 100px;
         }
        width: 142px;
        // @media (max-width: 767px){
        //     width: 95px;
        // }
    }
    .text-red {
        color: nth($red-shades, 4);
    }

   .card-footer {
        button {
            font-size: $font-sizes-5 !important;
             @media screen and (max-width: 768px) {              
                font-size: $font-sizes-3;
         } 
        }
    } 
}

.btn-expenses {
    display: inline-flex;
    align-items: center;
}
 
 .top-five-factors {
     @include custom-device(768px, 1920px) {
         display: inline-block;
         margin-left: -40px;
     }

     @extend .important-factors;
 }

 .shiftLeft {
     @include media-breakpoint-only(md) {
         margin-left: -40px;
     }
 }
        
/***********************
End Ability Result
************************/

 .class-margin {
     margin: 0px 0 0 17px;
 }




